.textFeedback {
    width: 129px;
    height: 21px;

    /* P9 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-align: center;

    transform: rotate(-90deg);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    
}
/* .btnFeedback {
    padding: 10px 12px;
    gap: 10px;
    width: 45px;
    height: 250px;
    right: 0;
    background-color: #43B5EF;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #000;
}

.btnFeedback:hover {
    background: #1155BB;
    color: #FFFFFF;
} */